import { createStore } from 'vuex'

export default createStore({
  state: {
    menuTitleone: '認識思倍傑',
    aboTitle: '公司簡介',
    aboEntitle: 'WHY SPJ',
    hisTitle: '歷史成就',
    tecTitle: '專業技術',
    comTitle: '幸福企業',
    menuTitletwo: '網站設計',
    webEntitle: 'WEB DESIGN',
    webTitle: 'RWD客製化網站',
    ESGTitle: 'ESG/CSR網站建置',
    busTitle: '好架網',
    menuTitleThree: '軟體專案開發',
    sysEntitle: 'SOFTWARE',
    sysTitle: '響應式EIP企業入口',
    menuTitlefour: '人才招募',
    recEntitle: 'JOIN US'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
