<!-- 網站選單元件 -->
<template>
  <div id="header_nav">
 <div class="logo">
  <router-link to="./" @click="toguid('./','home')">
    <img src="@/assets/logo.svg" />
  </router-link>
 </div>
  <div class="dropdown color-btn">
    <a
      class="btn dropdown-toggle color_change"
      href="#"
      role="button"
      id="dropdownMenuLink"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      title="點我換色"
    >
    </a>
    <ul class="dropdown-menu color_option" aria-labelledby="dropdownMenuLink">
      <li v-on="colorOne()" class="colorOne">
        <div class="dropdown-item"><img src="@/assets/color1.png" /></div>
      </li>
      <li v-on="colorTwo()" class="colorTwo">
        <div class="dropdown-item"><img src="@/assets/color2.png" /></div>
      </li>
      <li v-on="colorThree()" class="colorThree">
        <div class="dropdown-item"><img src="@/assets/color3.png" /></div>
      </li>
    </ul>
  </div>
  <div class="menu-btn" v-on="menuclick()">
    <a class="menu-open" href="javascript:void(0)">
      <i class="icon-menu"></i>
    </a>
    <a class="menu-close" href="javascript:void(0)">
      <i class="icon-close"></i>
    </a>
  </div>
  <div class="overlay">
    <div class="menu">
      <ul class="main_link">
        <li class="header_area link_box">
          <router-link to="./" @click="toguid('./','home')"><img src="@/assets/logo.svg" /></router-link >
        </li>
        <li class="link_box">
          <router-link to="/about" @click="toguid('/about','about')">{{ $store.state.menuTitleone }}</router-link>
          <ul class="sec_link">
            <li><router-link to="/about" @click="toguid('/about','about')">{{$store.state.aboTitle}}</router-link></li>
            <li><router-link to="/history" @click="toguid('/history','history')">{{ $store.state.hisTitle }}</router-link></li>
            <li><router-link to="/about" @click="toguid('/about','technology')">{{ $store.state.tecTitle }}</router-link></li>
            <li><router-link to="/company" @click="toguid('/company','company')">{{ $store.state.comTitle }}</router-link></li>
          </ul>
        </li>
        <li class="link_box">
          <router-link to="/webdesign" @click="toguid('/webdesign','webdesign')">{{ $store.state.menuTitletwo }}</router-link>
          <ul class="sec_link">
            <li><router-link to="/webdesign" @click="toguid('/webdesign','webdesign')">{{$store.state.webTitle}}</router-link></li>
            <li><router-link to="/ESG" @click="toguid('/ESG','ESG')">{{$store.state.ESGTitle}}</router-link></li>
            <li><router-link to="/webdesign"  @click="toguid('/webdesign','PS2022082600009')">電子賀卡/APP設計</router-link></li>
            <li><router-link to="/bizweb" @click="toguid('/bizweb','bizweb')">{{ $store.state.busTitle }}</router-link></li>
          </ul>
        </li>
        <li class="link_box">
          <router-link to="/webdesign" @click="toguid('/webdesign', 'All')">成功案例</router-link>
          <ul class="sec_link">
            <li><router-link to="/webdesign" @click="toguid('/webdesign','PS2022101400001')">中鋼集團</router-link></li>
            <li><router-link to="/webdesign"  @click="toguid('/webdesign','PS2022082600006')">農漁會專區</router-link></li>
            <li><router-link to="/webdesign"  @click="toguid('/webdesign','PS2022082600001')">上市櫃公司</router-link></li>
          </ul>
        </li>
        <li class="link_box menu_bg"></li>
        <li class="link_box">
          <router-link to="/system" @click="toguid('/system','system')">{{$store.state.menuTitleThree}}</router-link>
          <ul class="sec_link">
            <li><router-link to="/system" @click="toguid('/system','system')">{{$store.state.sysTitle}}</router-link></li>
            <li><router-link to="/system" @click="toguid('/system','enterprise')">環安衛系統</router-link></li>
            <li><router-link to="/system" @click="toguid('/system','commerce')">電子商務系統</router-link></li>
            <li><router-link to="/system" @click="toguid('/system','enterprise')">企業E化專案</router-link></li>
          </ul>
        </li>
        <li class="link_box"><router-link to="/recruit">{{ $store.state.menuTitlefour }}</router-link></li>
        <li class="link_box">
          <ul class="contact_txt">
            <li>07 554-3800</li>
            <li>80453 高雄市鼓山區明華路305號17樓之2</li>
            <li><a href="mailto:web@spadej.com">web@spadej.com</a></li>
          </ul>
        </li>
        <li class="link_box">
          <a href="mailto:web@spadej.com">聯絡我們<i class="icon-mail"></i></a>
        </li>
      </ul>
    </div>
  </div>
</div>
</template>
<style lang="scss" scoped>
#header_nav{
  position: relative;
  &::before{
    content: "";
    position: absolute;
    background: rgba(255, 255, 255, 0.1);
    height: 95px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    backdrop-filter: blur(7px);
  }
}
#header_nav.Non{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &::before{
    background: rgb(var(--navcolor));
    opacity: .9;
    box-shadow: 0px 3px 5px var(--shodowColor);
  }
}
.logo{
  position: fixed;
  top: 1rem;
  left: 4rem;
  z-index: 9;
  img{
    width: 200px;
  }
}
.color-btn {
  position: fixed;
  top: 2rem;
  right: 10rem;
  z-index: 1;
}
.color_change {
  position: relative;
  top: 0;
  right: 0;
  background: url(@/assets/color_btn.svg) no-repeat;
  width: 40px;
  height: 40px;
}
.color_change::after {
  border: 0;
}
.dropdown-menu.color_option {
  width: auto;
  min-width: 50px !important;
  background: rgba(255, 255, 255, 80%);
  li {
    max-width: 100px;
  }
}
.color_orange {
  width: 30px;
  height: 30px;
  background: var(--maincolor);
}
.dropdown-item.active,
.dropdown-item:active {
  background: #fff !important;
}
.dropdown-item {
  padding: 0 0.5rem;
  margin: 0.5rem 0;
}
// 選單
.overlay {
  position: fixed;
  top: 0;
  z-index: 998;
  display: none;
  overflow: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.main_link li {
  font-size: 2rem;
}
.sec_link {
  margin-top: 1rem;
}
.sec_link li {
  font-size: 1.25rem;
}
h3 {
  color: #e9e9e9;
  text-align: center;
  margin: 0;
  padding: 10px 0 0;
}

h4 {
  color: #333;
  text-align: center;
  margin: 0;
  padding: 10px 0 0;
}

a {
  font-size: 1.25rem;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
/* OPEN / CLOSE BTNS */
.menu-btn {
  position: fixed;
  top: 2rem;
  right: 4rem;
  z-index: 999;
  display: inline;
  font-size: 40px;
}
.menu-btn a {
  text-decoration: none;
}
.icon-menu,
.icon-close {
  font-size: 2rem;
  color: var(--white);
  font-weight: bold;
  padding: 1.25rem;
}
.menu-close .icon-menu {
  display: none;
}
/* OVERLAY */
.menu {
  width: 100%;
  height: 100%;
}
.menu .main_link {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 120px 1fr 1fr 120px;
}
// 第一版
:root[theme="web1"] {
  .menu .main_link .link_box:hover {
    background: var(--menu_hover_color);
    transition-delay: 0ms !important;
  }
  .menu .main_link .link_box:nth-child(1):hover {
    background: var(--menu_bg);
  }
  .menu .main_link .link_box {
    background: var(--menu_bg);
  }
  .menu .main_link .link_box.menu_bg {
    background: url(@/assets/menu_img.png) no-repeat;
    background-size: cover !important;
  }
  .menu .main_link .menu_bg:hover {
    background: url(@/assets/menu_img.png) no-repeat;
  }
  .menu .main_link .link_box a {
    width: 100%;
    color: var(--white);
    opacity: 0.8;
    font-weight: 700;
    /* vertical-align: middle; */
  }
  .menu .main_link .link_box a:hover {
    opacity: 1;
  }
}
@media screen and (max-width:767px) {
  .menu .main_link .link_box{
    padding: 1rem 1rem;
  }
  .logo img,.header_area img{
    width: 170px;
  }
  :root[theme="web1"] {
    .menu .main_link .menu_bg {
    display: none;
  }
  }
  :root[theme="web2"] {
    .menu .main_link .menu_bg {
    display: none;
  }
  }
  :root[theme="web3"] {
    .menu .main_link .menu_bg {
    display: none;
  }
  }

}
// 第二版
:root[theme="web2"] {
  .menu .main_link .link_box:hover {
    background: var(--menu_hover_color);
    transition-delay: 0ms !important;
  }
  .menu .main_link .link_box:nth-child(1):hover {
    background: var(--menu_bg);
  }
  .menu .main_link .link_box {
    background: var(--menu_bg);
  }
  .menu .main_link .link_box.menu_bg {
    background: url(@/assets/menu_img.png) no-repeat;
    background-size: cover !important;
  }
  .menu .main_link .menu_bg:hover {
    background: url(@/assets/menu_img.png) no-repeat;
  }
  .menu .main_link .link_box a {
    width: 100%;
    color: var(--white);
    opacity: 0.8;
    font-weight: 700;
    /* vertical-align: middle; */
  }
  .menu .main_link .link_box a:hover {
    opacity: 1;
  }
}

// 第三版
:root[theme="web3"] {
  .menu .main_link .link_box:hover {
    background: var(--menu_hover_color);
    transition-delay: 0ms !important;
  }
  .menu .main_link .link_box:nth-child(1):hover {
    background: var(--menu_bg);
  }
  .menu .main_link .link_box {
    background: var(--menu_bg);
  }
  .menu .main_link .link_box.menu_bg {
    background: url(@/assets/menu_img.png) no-repeat;
    background-size: cover !important;
  }
  .menu .main_link .menu_bg:hover {
    background: url(@/assets/menu_img.png) no-repeat;
  }
  .menu .main_link .link_box a {
    width: 100%;
    color: var(--white);
    opacity: 0.8;
    font-weight: 700;
    /* vertical-align: middle; */
  }
  .menu .main_link .link_box a:hover {
    opacity: 1;
  }
}
.menu .main_link .link_box {
  margin: 0;
  float: left;
  list-style: none;
  padding: 2rem 3rem 2rem 6rem;
  -webkit-transition: all 0.2s linear 0s;
  -moz-transition: all 0.2s linear 0s;
  -o-transition: all 0.2s linear 0s;
  transition-property: all 0.2s linear 0s;
  border: 1px solid rgba(255, 255, 255, 40%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  transition: background-color 0.3s ease-in-out 0s, opacity 0.125s ease 0s;
}
.overlay.menu-visible .menu .main_link .link_box {
  opacity: 1;
  position: relative;
}
.menu .main_link .header_area {
  width: 100%;
  height: 120px;
  text-align: start;
  display: block;
  padding: 2rem 0 0 6rem;
  border: 1px solid rgba(255, 255, 255, 40%);
  border-top: 0;
  border-left: 0;
  border-right: 0;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}
.header_area{
  img{
    width: 200px;
  }
}
.menu .main_link .link_box:nth-child(2)::before,
.menu .main_link .link_box:nth-child(3)::before,
.menu .main_link .link_box:nth-child(4)::before,
.menu .main_link .link_box:nth-child(6)::before,
.menu .main_link .link_box:nth-child(7)::before,
.menu .main_link .link_box:nth-child(9)::before {
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: auto;
  height: auto;
  color: rgba(255, 255, 255, 20%);
  font-size: 3rem;
}
.menu .main_link .link_box:nth-child(2) {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  transition-delay: 300ms;
}
.menu .main_link .link_box:nth-child(2)::before {
  content: "WHY SPJ";
}
.menu .main_link .link_box:nth-child(3) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  transition-delay: 200ms;
}
.menu .main_link .link_box:nth-child(3)::before {
  content: "WEB DESIGN";
}
.menu .main_link .link_box:nth-child(4) {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  transition-delay: 500ms;
}
.menu .main_link .link_box:nth-child(4)::before {
  content: "CASE STUDY";
}
.menu .main_link .link_box:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  transition-delay: 200ms;
}
.menu .main_link .link_box:nth-child(6) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  transition-delay: 600ms;
}
.menu .main_link .link_box:nth-child(6)::before {
  content: "SOFTWARE";
}
.menu .main_link .link_box:nth-child(7) {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  transition-delay: 300ms;
}
.menu .main_link .link_box:nth-child(7)::before {
  content: "JOIN US";
}
.menu .main_link .link_box:nth-child(8) {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 5;
  transition-delay: 400ms;
}
.menu .main_link .link_box:nth-child(9) {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 5;
  transition-delay: 400ms;
}
.menu .main_link .link_box:nth-child(9)::before {
  content: "CONTACT US";
}
ul {
  list-style: none;
  padding-left: 0;
}
.sec_link li {
  margin: 1rem auto;
}
.contact_txt li {
  font-size: 1.125rem;
  color: var(--white);
  text-align: end;
}
.link_box > a {
  font-size: 1.75rem;
  font-weight: bold;
  /* margin-bottom: 1rem; */
}
.icon-mail {
  margin-left: 1rem;
}
.menu .main_link .link_box a:hover {
  font-weight: bold;
  color: var(--white);
  opacity: 1;
}
.menu-open .icon-close {
  display: none;
}
.allexamples {
  position: absolute;
  bottom: 0;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  background: #e9e9e9;
  padding: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  position: fixed;
}

/* 選單固定 */
.icon-menu.navFixed {
  opacity: 0.94;
  transition: opacity 0.5s ease-out;
  // background: var(--maincolor) 50%;
  padding: 1.25rem;
  border-radius: 50%;
}

// 選單按鈕
.menu-close {
  display: none;
}
.menu-close.show {
  display: block;
}
.menu-open {
  display: block;
}
.menu-open.hidden {
  display: none;
}
/* RESPONSIVE */
@media screen and (max-width: 1200px) {
  .menu .main_link .link_box:nth-child(8) {
    padding: 1rem;
  }
}
@media screen and (max-width: 991px) {
  .menu .main_link .link_box {
    padding: 1rem;
  }
  .menu .main_link .link_box[data-v-6e1afce0]:nth-child(2)::before,
  .menu .main_link .link_box[data-v-6e1afce0]:nth-child(3)::before,
  .menu .main_link .link_box[data-v-6e1afce0]:nth-child(4)::before,
  .menu .main_link .link_box[data-v-6e1afce0]:nth-child(6)::before,
  .menu .main_link .link_box[data-v-6e1afce0]:nth-child(7)::before,
  .menu .main_link .link_box[data-v-6e1afce0]:nth-child(9)::before {
    font-size: 1.75rem;
  }
}
@media (min-width: 768px) {
  .menu .main_link .link_box:nth-child(2) {
    border: 0;
  }
  .menu .main_link .link_box:nth-child(3) {
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .menu .main_link .link_box:nth-child(4) {
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .menu .main_link .link_box:nth-child(5) {
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }
  .menu .main_link .link_box:nth-child(6) {
    border-bottom: 0;
    border-right: 0;
  }
  .menu .main_link .link_box:nth-child(7) {
    border-bottom: 0;
    border-right: 0;
  }
  .menu .main_link .link_box:nth-child(8) {
    border-bottom: 0;
    border-right: 0;
  }
  .menu .main_link .link_box:nth-child(9) {
    border-bottom: 0;
    border-right: 0;
  }
}
@media screen and (max-width: 767px) {
    .logo{
    left: 1rem;
    top: 1.25rem;
  }
   .color-btn{
    right: 6rem;
    top: 1.85rem;
  }
  .menu-btn {
    right: 0px;
  }
  .menu .main_link .link_box {
    float: none;
    width: 100%;
    height: 25%;
    border: 0;
  }
  .menu .main_link {
    display: block;
  }
  .menu .main_link .header_area {
    padding: 1rem 0 0rem 1rem;
  }
  .menu .main_link .link_box {
    height: auto;
    padding: 2rem 1rem;
    border-bottom: 1px solid #fff;
  }
  .menu .main_link .link_box:nth-child(5) {
    min-height: 300px;
  }
}
@media screen and (max-width: 375px) {
  .color-btn{
    right: 75px;
  }
  .logo{
    top: 1.5rem;
  }
  .logo img{
    width: 150px;
  }
}
</style>
<script>
import $ from 'jquery'
export default {
  methods: {
    menuclick: function () {
      $(document).ready(function () {
        $('.menu-open').click(function () {
          $('.overlay').fadeIn(200).addClass('menu-visible')
          $('.menu-open').addClass('hidden')
          $('.menu-close').addClass('show')
        })
        $('.menu a').on('click', function () {
          $('.overlay').fadeOut(1500).removeClass('menu-visible')
          $('.menu-close').removeClass('show')
          $('.menu-open').removeClass('hidden')
        })
        $('.menu-close').on('click', function () {
          $('.overlay').fadeOut(1500).removeClass('menu-visible')
          $('.menu-close').removeClass('show')
          $('.menu-open').removeClass('hidden')
          $('.link_box').addClass('hidden')
        })
        $(function () {
          $(window).scroll(function () {
            if ($(this).scrollTop() > 525) {
              /* 要滑動到選單的距離 */
              $('.icon-menu').addClass('navFixed') /* 幫選單加上固定效果 */
              $('#header_nav').addClass('Non')
            } else {
              $('.icon-menu').removeClass('navFixed') /* 移除選單固定效果 */
              $('#header_nav').removeClass('Non')
            }
          })
        })
      })
    },
    colorOne: function () {
      $(document).ready(function () {
        $('.colorOne').click(function () {
          const themechange = document.querySelector('html')
          themechange.setAttribute('theme', 'web1')
          sessionStorage.setItem('Color', 'ColorOne')
        })
      })
    },
    colorTwo: function () {
      $(document).ready(function () {
        $('.colorTwo').click(function () {
          const themechange = document.querySelector('html')
          themechange.setAttribute('theme', 'web2')
          sessionStorage.setItem('Color', 'ColorTwo')
        })
      })
    },
    colorThree: function () {
      $(document).ready(function () {
        $('.colorThree').click(function () {
          const themechange = document.querySelector('html')
          themechange.setAttribute('theme', 'web3')
          sessionStorage.setItem('Color', 'ColorThree')
        })
      })
    },
    toguid (path, id) {
      /* eslint-disable */
      // var path = path
      const Id = id
      localStorage.setItem('toId', Id)
      this.$router.push(path)
      localStorage.setItem('Reload', 'True')
    }
  },
  created () {
    var Color = sessionStorage.getItem('Color')
    if (Color !== null || Color !== '') {
      const themechange = document.querySelector('html')
      switch (Color) {
        case 'ColorOne':
          themechange.setAttribute('theme', 'web1')
          break
        case 'ColorTwo':
          themechange.setAttribute('theme', 'web2')
          break
        case 'ColorThree':
          themechange.setAttribute('theme', 'web3')
          break
        default:
          break
      }
    }
  }
}
</script>
