import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@popperjs/core'
import 'bootstrap'
import MasonryWall from '@yeger/vue-masonry-wall'
// API串接(技術)
import VueAxios from 'vue-axios'
import Axios from 'axios'
// import Ajax package
// createApp(App).use(store).use(router).use(MasonryWall).mount('#app')
// 跳轉後返回頂部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
createApp(App).use(store).use(router).use(VueAxios, Axios).use(MasonryWall).mount('#app')
