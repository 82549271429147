<!-- 首頁Banner元件 -->
<template>
    <!-- 滾動空間 -->
   <div class="scroll_space">
    <div class="home container-fuild">
      <div class="content">
    <div class="fold-content" id="base-content">
      <!-- CONTENT INSIDE HERE -->
      <!-- 第一個 -->
      <div class="content__line">
        <span class="type type--1">思倍傑資訊</span>
        <span class="type type--2">SpadeJ</span>
        <span class="type type--1">SpadeJ</span>
        <span class="type type--2">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--3">SpadeJ</span>
        <span class="type type--4 pd"></span>
        <span class="type type--3">網站設計</span>
        <span class="type type--4">系統開發</span>
      </div>
      <div class="content__line">
        <span class="type type--6">SpadeJ</span>
        <span class="type type--1">系統開發</span>
        <span class="type type--6 pd"></span>
        <span class="type type--1">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--5 sec_txt"
          >黑桃11，代表上承王牌，下接數字，扮演關鍵性的角色</span
        >
        <span class="type type--2 pd"></span>
        <span class="type type--5">SpadeJ</span>
      </div>
      <!-- 順序與第一個接在一起 -->
      <div class="content__line">
        <span class="type type--1">思倍傑資訊</span>
        <span class="type type--2">SpadeJ</span>
        <span class="type type--1">SpadeJ</span>
        <span class="type type--7">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--3">SpadeJ</span>
        <span class="type type--4 pd"></span>
        <span class="type type--3">網站設計</span>
        <span class="type type--4">系統開發</span>
      </div>
      <div class="content__line">
        <span class="type type--6">SpadeJ</span>
        <span class="type type--1">系統開發</span>
        <span class="type type--6 pd"></span>
        <span class="type type--1">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--5 sec_txt"
          >黑桃11，代表上承王牌，下接數字，扮演關鍵性的角色</span
        >
        <span class="type type--2 pd"></span>
        <span class="type type--5">SpadeJ</span>
      </div>
      <!-- 重複區塊 -->
      <div class="content__line">
        <span class="type type--1">思倍傑資訊</span>
        <span class="type type--2">SpadeJ</span>
        <span class="type type--1">SpadeJ</span>
        <span class="type type--7">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--3">SpadeJ</span>
        <span class="type type--4 pd"></span>
        <span class="type type--3">網站設計</span>
        <span class="type type--4">系統開發</span>
      </div>
      <div class="content__line">
        <span class="type type--6">SpadeJ</span>
        <span class="type type--1">系統開發</span>
        <span class="type type--6 pd"></span>
        <span class="type type--1">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--5 sec_txt"
          >黑桃11，代表上承王牌，下接數字，扮演關鍵性的角色</span
        >
        <span class="type type--2 pd"></span>
        <span class="type type--5">SpadeJ</span>
      </div>
      <!-- 重複區塊 -->
      <div class="content__line">
        <span class="type type--1">思倍傑資訊</span>
        <span class="type type--2">SpadeJ</span>
        <span class="type type--1">SpadeJ</span>
        <span class="type type--7">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--3">SpadeJ</span>
        <span class="type type--4 pd"></span>
        <span class="type type--3">網站設計</span>
        <span class="type type--4">系統開發</span>
      </div>
      <div class="content__line">
        <span class="type type--6">SpadeJ</span>
        <span class="type type--1">系統開發</span>
        <span class="type type--6 pd"></span>
        <span class="type type--1">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--5 sec_txt"
          >黑桃11，代表上承王牌，下接數字，扮演關鍵性的角色</span
        >
        <span class="type type--2 pd"></span>
        <span class="type type--5">SpadeJ</span>
      </div>
      <!-- 重複區塊 -->
      <div class="content__line">
        <span class="type type--1">思倍傑資訊</span>
        <span class="type type--2">SpadeJ</span>
        <span class="type type--1">SpadeJ</span>
        <span class="type type--7">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--3">SpadeJ</span>
        <span class="type type--4 pd"></span>
        <span class="type type--3">網站設計</span>
        <span class="type type--4">系統開發</span>
      </div>
      <div class="content__line">
        <span class="type type--6">SpadeJ</span>
        <span class="type type--1">系統開發</span>
        <span class="type type--6 pd"></span>
        <span class="type type--1">SpadeJ</span>
      </div>
      <div class="content__line">
        <span class="type type--5 sec_txt"
          >黑桃11，代表上承王牌，下接數字，扮演關鍵性的角色</span
        >
        <span class="type type--2 pd"></span>
        <span class="type type--5">SpadeJ</span>
      </div>
    </div>
    <div class="screen" id="fold-effect">
      <div class="wrapper-3d">
        <div class="fold fold-before"></div>
        <div class="fold fold-center"></div>
        <div class="fold fold-after"></div>
      </div>
    </div>
    <button class="btn-debug" id="btn-debug" style="visibility: hidden">
      See Planes
    </button>
  </div>
  <poker></poker>
  </div>
  </div>

</template>

<style scoped lang="scss">
.home {
  overflow: hidden;
  background: var(--maincolor);
  position: -webkit-sticky;
  top: 0px;
  transform: translate(-2px);
  position: sticky;
  top: 0;
}
.scroll_space{
  height: 230vh;
}
.screen {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.fold-scroller {
  height: 100%;
}

.fold-content {
  pointer-events: all;
  width: 100%;
}

.fold-size-fix {
  width: 100%;
  height: 100%;
}

.fold-before .fold-size-fix {
  transform-origin: bottom center;
}

.fold-after .fold-size-fix {
  transform-origin: top center;
}

#base-content {
  display: none;
}

/*  Debug */
.debug .fold:nth-child(2n) {
  background: #dadada;
}

.debug .fold:nth-child(2n + 1) {
  background: #bababa;
}

.btn-debug {
  position: fixed;
  top: 10px;
  padding: 1rem;
  font-size: 16px;
  text-transform: uppercase;
  right: 10px;
  cursor: pointer;
  z-index: 100;
  display: none;
}

.square {
  width: 100%;
  padding-bottom: 100%;
  background: coral;
}

.rectangle {
  width: 100%;
  padding-bottom: 50%;
  background: coral;
  box-shadow: 1px 0 coral, 0 1px coral;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.message {
  background: var(--color-text);
  color: var(--color-bg);
  padding: 1rem;
  text-align: center;
  display: none;
}

.frame {
  padding: 3rem 5vw;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
  margin-right: 1rem;
}

.frame__demos {
  margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
  color: var(--color-text);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 13rem);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 53em) {
  .message {
    display: none;
  }

  .frame {
    position: fixed;
    text-align: left;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 3rem;
    pointer-events: none;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... demos";
  }

  .frame__title-wrap {
    grid-area: title;
    display: flex;
  }

  .frame__title {
    margin: 0;
  }

  .frame__tagline {
    position: relative;
    margin: 0 0 0 1rem;
    padding: 0 0 0 1rem;
    opacity: 0.5;
  }

  .frame__demos {
    margin: 0;
    grid-area: demos;
    justify-self: end;
  }

  .frame__links {
    grid-area: links;
    padding: 0;
    justify-self: end;
  }

  .frame a {
    pointer-events: auto;
  }

  .content {
    height: 100vh;
    justify-content: center;
  }
}
/* -------------------------- */
.content__line {
  text-align: center;
  margin: 1vh 0 2vh -5vw;
  display: flex;
  backface-visibility: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  padding: 0 6rem;
}

.type {
  --type-size: 8vw;
  font-size: var(--type-size);
  line-height: 0.97;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 0 3.25rem 0 0;
  color: #fff;
  opacity: 0.75;
}

.type--1 {
  font-family: "Gotu", sans-serif;
  font-weight: 100;
}

.type--2 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.type--3 {
  font-family: "Lexend Tera", sans-serif;
  font-weight: 300;
  font-size: calc(var(--type-size) * 1.04);
  padding-left: 5rem;
}

.type--4 {
  font-family: "Silkscreen", sans-serif;
  font-weight: 400;
}

.type--5 {
  font-family: "Shippori Antique B1", sans-serif;
  font-weight: 700;
  line-height: 0.97;
}

.type--6 {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.type--7 {
  font-family: "Aclonica", sans-serif;
  font-weight: 900;
  line-height: 0.7;
}

/* Effect Styles */

.fold {
  overflow: hidden;
  width: 100vw;
  height: 80vh;
  will-change: transform;
}

.wrapper-3d {
  position: relative;
  perspective: 250px;
  transform-style: preserve-3d;
}

.fold-scroller {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.fold-center {
  transform-origin: center center;
  transform: translateZ(0px) translate3d(0px, 0, 0) rotateX(0)
    scale3d(1, 1.002004, 1);
  height: calc(80vh - 120px);
}

.fold-after {
  position: absolute;
  transform-origin: top center;
  transform: translateZ(0px) translate3d(0px, 74vh, 0) rotateX(120deg)
    scale3d(1, 1.002004008, 1);
  right: 0;
  left: 0;
  top: -63px;
  bottom: 0;
  padding-top: 3rem;
}

/* .fold-size-fix {
  transform: scaleY(0.995);
} */
.fold-size-fix {
  transform-origin: center center;
  transform: scaleY(0.998);
}

.fold-before {
  position: absolute;
  transform-origin: bottom center;
  transform: translateZ(0px) translate3d(0, -80vh, 0) rotateX(-120deg)
    scale3d(1, 1.002004008, 1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 5rem;
}

.fold-before h1 {
  /* color: red !important; */
}

.fold-after .fold-scroller {
  transform: translateY(-100%);
}

.fold-before .fold-scroller {
  transform: translateY(100%);
}

/* Debug */

.debug .fold:nth-child(2n) {
  background: #dadada;
}

.debug .fold:nth-child(2n + 1) {
  background: #bababa;
}

.btn-debug {
  position: fixed;
  top: 10px;
  padding: 1rem;
  font-size: 16px;
  text-transform: uppercase;
  right: 10px;
  cursor: pointer;
  z-index: 100;
}
.sec_txt {
  font-size: 4.5rem;
  padding: 2rem 3rem 2rem 0rem;
}
.pd {
  padding: 0 12rem;
}
@media screen and (min-width: 2560px) {
  .poker_box {
    margin-top: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .poker_box {
    margin-top: 18%;
  }
  // 背景文字
  .sec_txt {
    font-size: 3rem;
  }
  .type {
    --type-size: 4.25vw;
    line-height: 1;
  }
}
@media screen and (max-width: 1199px) {
  // 背景文字
  .content__line {
    padding: 0 4rem;
  }
  .sec_txt {
    font-size: 2.5rem;
    padding: 2rem 0rem 2rem 0rem;
  }
  .type {
    --type-size: 9.5vw;
    line-height: 1;
  }
  .fold-after {
    top: -38px;
  }
}
@media screen and (max-width: 991px) {
  // 背景文字
  .sec_txt {
    font-size: 1.85rem;
  }
  .type {
    --type-size: 8vw;
    line-height: 1;
  }
  .fold-after {
    padding-top: 2rem;
  }
  .content__line {
    padding: 0 3rem;
  }
}
@media screen and (max-width: 767px) {
  .sec_txt {
    font-size: 1.5rem;
    padding: 0;
  }
  .content__line {
    padding: 0 1.5rem;
  }
  .content {
    height: 85vh;
  }
  //背景文字
  .screen {
    height: 75vh;
  }
  .sec_txt {
    font-size: 1.75rem;
    white-space: normal;
    width: 100%;
    position: absolute;
    text-align: initial;
    padding: 1rem 0;
  }
  .type {
    --type-size: 10vw;
    line-height: 1.1;
  }
  .type--2.pd {
    margin-top: 5rem;
  }
  .fold-after {
    padding-top: 0;
    top: -70px;
  }
}
@media screen and (max-width: 375px) {
  .sec_txt {
    font-size: 20px;
  }
}
</style>

<script>
import poker from '@/components/PokerBlock.vue'
export default {
  components: { poker },
  mounted () {
    // 背景
    (function () {
      const wrapper = document.getElementById('fold-effect')
      const btn = document.getElementById('btn-debug')

      const folds = Array.from(document.getElementsByClassName('fold'))

      const baseContent = document.getElementById('base-content')

      const toggleDebug = () => {
        wrapper.classList.toggle('debug')
      }
      btn.addEventListener('click', toggleDebug)

      const state = {
        disposed: false,
        targetScroll: 0,
        scroll: 0
      }

      function lerp (current, target, speed = 0.1, limit = 0.001) {
        let change = (target - current) * speed
        if (Math.abs(change) < limit) {
          change = target - current
        }
        return change
      }

      class FoldedDom {
        constructor (wrapper, folds = null, scrollers = null) {
          this.wrapper = wrapper
          this.folds = folds
          this.scrollers = []
        }

        setContent (baseContent, createScrollers = true) {
          const folds = this.folds
          if (!folds) return

          const scrollers = []

          for (let i = 0; i < folds.length; i++) {
            const fold = folds[i]
            const copyContent = baseContent.cloneNode(true)
            copyContent.id = ''
            let scroller
            if (createScrollers) {
              const sizeFixEle = document.createElement('div')
              sizeFixEle.classList.add('fold-size-fix')

              scroller = document.createElement('div')
              scroller.classList.add('fold-scroller')
              sizeFixEle.append(scroller)
              fold.append(sizeFixEle)
            } else {
              scroller = this.scrollers[i]
            }
            scroller.append(copyContent)

            scrollers[i] = scroller
          }
          this.scrollers = scrollers
        }

        updateStyles (scroll, skewAmp, rotationAmp) {
          const folds = this.folds
          const scrollers = this.scrollers

          for (let i = 0; i < folds.length; i++) {
            const scroller = scrollers[i]

            scroller.children[0].style.transform = `translateY(${scroll}px)`
          }
        }
      }
      /*eslint-disable*/
      let insideFold;

      const centerFold = folds[Math.floor(folds.length / 2)];
      const tick = () => {
        if (state.disposed) return;
        state.targetScroll = -(
          document.documentElement.scrollTop || document.body.scrollTop
        );
        state.scroll += lerp(state.scroll, state.targetScroll, 0.1, 0.0001);

        insideFold.updateStyles(state.scroll);

        requestAnimationFrame(tick);
      };
      insideFold = new FoldedDom(wrapper, folds);
      insideFold.setContent(baseContent);

      tick();
    })();
  },
};
</script>
