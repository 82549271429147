// 正式機路由設定
import { createRouter, createWebHistory } from 'vue-router'
// 測試機路由設定
// import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: about
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/history',
    name: 'history',
    // component: history
    component: () => import('../views/HistoryView.vue')
  },
  {
    path: '/company',
    name: 'company',
    // component: company
    component: () => import('../views/CompanyView.vue')
  },
  {
    path: '/webdesign',
    name: 'webdesign',
    // component: WebdesignView
    component: () => import('../views/WebdesignView.vue')
  },
  {
    path: '/ESG',
    name: 'ESG',
    // component: ESG
    component: () => import('../views/ESGView.vue')
  },
  {
    path: '/bizweb',
    name: 'bizweb',
    // component: BuswebView
    component: () => import('../views/BuswebView.vue')
  },
  {
    path: '/System',
    name: 'system',
    // component: SystemView
    component: () => import('../views/SystemView.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    // component: RecruitingView
    component: () => import('../views/RecruitingView.vue')
  }
]

const router = createRouter({
  // 正式機路由設定
  history: createWebHistory(),
  // 測試機路由設定
  // history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (localStorage.getItem('Reload') === 'True') {
      localStorage.setItem('Reload', 'False')
      window.location.reload()
    }
    const ID = localStorage.getItem('toId')
    if (ID.toLowerCase().includes('ps20') && ID !== '') {
      const Interval = setInterval(() => {
        const Element = document.getElementById(ID)
        if (Element !== null && Element != null) {
          Element.click()
          clearInterval(Interval)
        }
      }, 100)
    }
    if (ID === 'home') {
      localStorage.setItem('toId', '')
      window.location.reload()
    }
    if (ID.toLowerCase().includes('company')) {
      localStorage.setItem('toId', '')
      window.location.reload()
      savedPosition = null
    }
    if (!ID.toLowerCase().includes('ps20') && !ID.toLowerCase().includes('home')) {
      return {
        el: '#' + ID,
        top: 100
      }
    } else {
      return {
        el: '#website',
        top: 100
      }
    }
  }
})

export default router
