<!-- 首頁Banner元件 -->
<template>
  <div class="poker_area">
    <div class="poker_box">
      <!-- 7-11 -->
      <div class="poker_group group1 d-flex">
        <div class="poker poker7">
          <img src="@/assets/7_img.svg" />
        </div>
        <div class="poker poker8">
          <img src="@/assets/8_img.svg" />
        </div>
        <div class="poker poker9">
          <img src="@/assets/9_img.svg" />
        </div>
        <div class="poker poker10">
          <img src="@/assets/10_img.svg" />
        </div>
        <div class="poker pokerJ">
          <img src="@/assets/J_img.svg" />
        </div>
      </div>
      <!-- 8-12 -->
      <div class="poker_group group2 d-flex">
        <div class="poker poker8">
          <img src="@/assets/8_img.svg" />
        </div>
        <div class="poker poker9">
          <img src="@/assets/9_img.svg" />
        </div>
        <div class="poker poker10">
          <img src="@/assets/10_img.svg" />
        </div>
        <div class="poker pokerJ">
          <img src="@/assets/J_img.svg" />
        </div>
        <div class="poker pokerQ">
          <img src="@/assets/Q_img.svg" />
        </div>
      </div>
      <!-- 9-13 -->
      <div class="poker_group group3 d-flex">
        <div class="poker poker9">
          <img src="@/assets/9_img.svg" />
        </div>
        <div class="poker poker10">
          <img src="@/assets/10_img.svg" />
        </div>
        <div class="poker pokerJ">
          <img src="@/assets/J_img.svg" />
        </div>
        <div class="poker pokerQ">
          <img src="@/assets/Q_img.svg" />
        </div>
        <div class="poker pokerK">
          <img src="@/assets/K_img.svg" />
        </div>
      </div>
      <!-- 10-A -->
      <div class="poker_group group4 d-flex">
        <div class="poker poker10">
          <img src="@/assets/10_img.svg" />
        </div>
        <div class="poker pokerJ">
          <img src="@/assets/J_img.svg" />
        </div>
        <div class="poker pokerQ">
          <img src="@/assets/Q_img.svg" />
        </div>
        <div class="poker pokerK">
          <img src="@/assets/K_img.svg" />
        </div>
        <div class="poker pokerA">
          <img src="@/assets/A_img.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

  <style scoped lang="scss">
.screen {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

.fold-scroller {
  height: 100%;
}

.fold-content {
  pointer-events: all;
  width: 100%;
}

.fold-size-fix {
  width: 100%;
  height: 100%;
}

.fold-before .fold-size-fix {
  transform-origin: bottom center;
}

.fold-after .fold-size-fix {
  transform-origin: top center;
}

#base-content {
  display: none;
}

/*  Debug */
.debug .fold:nth-child(2n) {
  background: #dadada;
}

.debug .fold:nth-child(2n + 1) {
  background: #bababa;
}

.btn-debug {
  position: fixed;
  top: 10px;
  padding: 1rem;
  font-size: 16px;
  text-transform: uppercase;
  right: 10px;
  cursor: pointer;
  z-index: 100;
  display: none;
}

.square {
  width: 100%;
  padding-bottom: 100%;
  background: coral;
}

.rectangle {
  width: 100%;
  padding-bottom: 50%;
  background: coral;
  box-shadow: 1px 0 coral, 0 1px coral;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: "";
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

a {
  text-decoration: none;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  color: var(--color-link-hover);
  outline: none;
}

.message {
  background: var(--color-text);
  color: var(--color-bg);
  padding: 1rem;
  text-align: center;
  display: none;
}

.frame {
  padding: 3rem 5vw;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
  margin-right: 1rem;
}

.frame__demos {
  margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
  color: var(--color-text);
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 13rem);
  position: relative;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 53em) {
  .message {
    display: none;
  }

  .frame {
    position: fixed;
    text-align: left;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100vh;
    padding: 3rem;
    pointer-events: none;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... demos";
  }

  .frame__title-wrap {
    grid-area: title;
    display: flex;
  }

  .frame__title {
    margin: 0;
  }

  .frame__tagline {
    position: relative;
    margin: 0 0 0 1rem;
    padding: 0 0 0 1rem;
    opacity: 0.5;
  }

  .frame__demos {
    margin: 0;
    grid-area: demos;
    justify-self: end;
  }

  .frame__links {
    grid-area: links;
    padding: 0;
    justify-self: end;
  }

  .frame a {
    pointer-events: auto;
  }

  .content {
    height: 100vh;
    justify-content: center;
  }
}
/* -------------------------- */
.content__line {
  text-align: center;
  margin: 1vh 0 2vh -5vw;
  display: flex;
  backface-visibility: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  padding: 0 6rem;
}

.type {
  --type-size: 8vw;
  font-size: var(--type-size);
  line-height: 0.97;
  white-space: nowrap;
  text-transform: uppercase;
  padding: 0 3.25rem 0 0;
  color: #fff;
  opacity: 0.75;
}

.type--1 {
  font-family: "Gotu", sans-serif;
  font-weight: 100;
}

.type--2 {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.type--3 {
  font-family: "Lexend Tera", sans-serif;
  font-weight: 300;
  font-size: calc(var(--type-size) * 1.04);
  padding-left: 5rem;
}

.type--4 {
  font-family: "Silkscreen", sans-serif;
  font-weight: 400;
}

.type--5 {
  font-family: "Shippori Antique B1", sans-serif;
  font-weight: 700;
  line-height: 0.97;
}

.type--6 {
  font-family: "Raleway", sans-serif;
  font-weight: 300;
}

.type--7 {
  font-family: "Aclonica", sans-serif;
  font-weight: 900;
  line-height: 0.7;
}

/* Effect Styles */

.fold {
  overflow: hidden;
  width: 100vw;
  height: 80vh;
  will-change: transform;
}

.wrapper-3d {
  position: relative;
  perspective: 250px;
  transform-style: preserve-3d;
}

.fold-scroller {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.fold-center {
  transform-origin: center center;
  transform: translateZ(0px) translate3d(0px, 0, 0) rotateX(0)
    scale3d(1, 1.002004, 1);
  height: calc(80vh - 120px);
}

.fold-after {
  position: absolute;
  transform-origin: top center;
  transform: translateZ(0px) translate3d(0px, 74vh, 0) rotateX(120deg)
    scale3d(1, 1.002004008, 1);
  right: 0;
  left: 0;
  top: -63px;
  bottom: 0;
  padding-top: 3rem;
}

/* .fold-size-fix {
    transform: scaleY(0.995);
  } */
.fold-size-fix {
  transform-origin: center center;
  transform: scaleY(0.998);
}

.fold-before {
  position: absolute;
  transform-origin: bottom center;
  transform: translateZ(0px) translate3d(0, -80vh, 0) rotateX(-120deg)
    scale3d(1, 1.002004008, 1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 5rem;
}

.fold-before h1 {
  /* color: red !important; */
}

.fold-after .fold-scroller {
  transform: translateY(-100%);
}

.fold-before .fold-scroller {
  transform: translateY(100%);
}

/* Debug */

.debug .fold:nth-child(2n) {
  background: #dadada;
}

.debug .fold:nth-child(2n + 1) {
  background: #bababa;
}

.btn-debug {
  position: fixed;
  top: 10px;
  padding: 1rem;
  font-size: 16px;
  text-transform: uppercase;
  right: 10px;
  cursor: pointer;
  z-index: 100;
}
.sec_txt {
  font-size: 4.5rem;
  padding: 2rem 3rem 2rem 0rem;
}
.pd {
  padding: 0 12rem;
}
/* poker */
.poker_area {
  position: absolute;
  right: 0%;
  width: 100%;
  height: 700px;
  bottom: 15%;
  // 啟用Banner2時關閉overflow
  overflow: hidden;
  transform: translateZ(1000px);
}
.poker_box {
  margin-top: 16%;
}
.poker {
  width: 250px;
  height: 350px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 1vmin 5vmin 4vmin rgb(0 0 0 / 15%),
    0.5vmin 4vmin 3vmin rgb(0 0 0 / 10%);
  position: relative;
  &::after {
    font-family: "icomoon";
    content: "\e906";
    font-size: 2rem;
    position: absolute;
    top: 4rem;
    left: 1rem;
    color: #3a3a3a;
  }
  img {
    position: absolute;
    bottom: 64%;
    right: 50%;
    width: 100%;
    height: 90%;
    transform: translate(50%, 70%);
  }
  &7 {
    position: relative;
    &::before {
      content: "7";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  &8 {
    position: relative;
    &::before {
      content: "8";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  &9 {
    position: relative;
    &::before {
      content: "9";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  &10 {
    position: relative;
    &::before {
      content: "10";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  &J {
    position: relative;
    &::before {
      content: "J";
      position: absolute;
      color: #fff;
      left: 20px;
      top: 2rem;
      font-size: 3rem;
      font-weight: 700;
    }
    &::after {
      top: 5rem;
      color: #fff;
    }
  }
  &Q {
    position: relative;
    &::before {
      content: "Q";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  &K {
    position: relative;
    &::before {
      content: "K";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  &A {
    position: relative;
    &::before {
      content: "A";
      position: absolute;
      color: #000;
      left: 17px;
      top: 1rem;
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
}
.poker_group {
  perspective: 78rem;
  perspective-origin: bottom right;
  // display: none;
  // visibility: hidden;
  &.active {
    display: block;
    visibility: visible;
    animation-fill-mode: forwards;
    // animation: poker 4.5s;
  }
}
.group1 {
  animation: poker1 12s infinite;
  .poker {
    &:nth-child(1) {
      transform: rotateX(38deg) rotateZ(343deg) translateZ(1vmin)
        rotateY(350deg) rotateX(323deg);
      position: absolute;
      right: 47rem;
    }
    &:nth-child(2) {
      transform: rotateX(45deg) rotateZ(349deg) translateZ(2vmin)
        rotateY(357deg) rotateX(318deg);
      position: absolute;
      right: 41rem;
      top: -1rem;
    }
    &:nth-child(3) {
      transform: rotateX(62deg) rotateZ(3deg) translateZ(5vmin) rotateY(357deg)
        rotateX(303deg);
      position: absolute;
      right: 34rem;
    }
    &:nth-child(4) {
      transform: rotateX(46deg) rotateZ(7deg) translateZ(6vmin) rotateY(366deg)
        rotateX(318deg);
      position: absolute;
      right: 25rem;
    }
    &:nth-child(5) {
      transform: rotateX(51deg) rotateZ(12deg) translateZ(7vmin) rotateY(369deg)
        rotateX(315deg);
      position: absolute;
      right: 14rem;
      width: 320px;
      height: 420px;
      background: var(--seccolor);
      top: -9rem;
    }
  }
}
.group2 {
  animation: poker2 12s infinite;
  .poker {
    &:nth-child(1) {
      transform: rotateX(34deg) rotateZ(351deg) translateZ(1vmin)
        rotateY(350deg) rotateX(325deg);
      position: absolute;
      right: 47rem;
    }
    &:nth-child(2) {
      transform: rotateX(45deg) rotateZ(356deg) translateZ(4vmin)
        rotateY(357deg) rotateX(318deg);
      position: absolute;
      right: 41rem;
      top: -1rem;
    }
    &:nth-child(3) {
      transform: rotateX(62deg) rotateZ(2deg) translateZ(7vmin) rotateY(359deg)
        rotateX(303deg);
      position: absolute;
      right: 34rem;
      top: -1rem;
    }
    &:nth-child(4) {
      transform: rotateX(45deg) rotateZ(11deg) translateZ(10vmin)
        rotateY(358deg) rotateX(319deg);
      position: absolute;
      right: 20rem;
      width: 320px;
      height: 420px;
      background: var(--seccolor);
      top: -9rem;
    }
    &:nth-child(5) {
      transform: rotateX(50deg) rotateZ(10deg) translateZ(13vmin)
        rotateY(368deg) rotateX(322deg);
      position: absolute;
      right: 13rem;
      top: 3rem;
    }
  }
}
.group3 {
  animation: poker3 12s infinite;
  .poker {
    &:nth-child(1) {
      transform: rotateX(34deg) rotateZ(351deg) translateZ(1vmin)
        rotateY(350deg) rotateX(325deg);
      position: absolute;
      right: 47rem;
    }
    &:nth-child(2) {
      transform: rotateX(45deg) rotateZ(356deg) translateZ(3vmin)
        rotateY(357deg) rotateX(318deg);
      position: absolute;
      right: 41rem;
      top: -1rem;
    }
    &:nth-child(3) {
      transform: rotateX(60deg) rotateZ(0deg) translateZ(6vmin) rotateY(360deg)
        rotateX(300deg);
      position: absolute;
      right: 31rem;
      width: 320px;
      height: 420px;
      background: var(--seccolor);
      top: -10rem;
    }
    &:nth-child(4) {
      transform: rotateX(45deg) rotateZ(9deg) translateZ(9vmin) rotateY(359deg)
        rotateX(320deg);
      position: absolute;
      right: 22rem;
    }
    &:nth-child(5) {
      transform: rotateX(49deg) rotateZ(11deg) translateZ(12vmin)
        rotateY(370deg) rotateX(317deg);
      position: absolute;
      right: 13rem;
      top: 4rem;
    }
  }
}
.group4 {
  animation: poker4 12s infinite;
  .poker {
    &:nth-child(1) {
      transform: rotateX(34deg) rotateZ(351deg) translateZ(1vmin)
        rotateY(350deg) rotateX(325deg);
      position: absolute;
      right: 47rem;
    }
    &:nth-child(2) {
      transform: rotateX(45deg) rotateZ(356deg) translateZ(4vmin)
        rotateY(357deg) rotateX(318deg);
      position: absolute;
      right: 37rem;
      width: 320px;
      height: 420px;
      background: var(--seccolor);
      top: -8rem;
    }
    &:nth-child(3) {
      transform: rotateX(57deg) rotateZ(0deg) translateZ(7vmin) rotateY(360deg)
        rotateX(305deg);
      position: absolute;
      right: 31rem;
    }
    &:nth-child(4) {
      transform: rotateX(45deg) rotateZ(9deg) translateZ(9vmin) rotateY(366deg)
        rotateX(320deg);
      position: absolute;
      right: 22rem;
    }
    &:nth-child(5) {
      transform: rotateX(49deg) rotateZ(10deg) translateZ(13vmin)
        rotateY(378deg) rotateX(317deg);
      position: absolute;
      right: 13rem;
      top: 4rem;
    }
  }
}
@media screen and (min-width: 2560px) {
  .poker_box {
    margin-top: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .poker_box {
    margin-top: 18%;
  }
  // 背景文字
  .sec_txt {
    font-size: 3rem;
  }
  .type {
    --type-size: 4.25vw;
    line-height: 1;
  }
}
@media screen and (max-width: 1199px) {
  .poker_group {
    position: absolute;
    right: -9rem;
    width: 100%;
  }
  .poker_area {
    height: 80%;
  }
  .poker_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 25%;
  }
  // 背景文字
  .content__line {
    padding: 0 4rem;
  }
  .sec_txt {
    font-size: 2.5rem;
    padding: 2rem 0rem 2rem 0rem;
  }
  .type {
    --type-size: 9.5vw;
    line-height: 1;
  }
  .fold-after {
    top: -38px;
  }
}
@media screen and (max-width: 991px) {
  .poker_area {
    height: 60%;
  }
  .content {
    height: 100vh;
  }
  .poker_group {
    right: -15rem;
  }
  .poker {
    width: 180px;
    height: 260px;
    &::after {
      font-size: 1.5rem;
      top: 3rem;
    }
    &::before {
      font-size: 1.5rem;
    }
  }
  .pokerJ::before {
    font-size: 2rem;
    top: 1rem;
  }
  // 背景文字
  .sec_txt {
    font-size: 1.85rem;
  }
  .type {
    --type-size: 8vw;
    line-height: 1;
  }
  .fold-after {
    padding-top: 2rem;
  }
  // 撲克牌型
  .group1 .poker:nth-child(1) {
    transform: rotateX(0) rotateZ(-21deg) translateZ(1vmin) rotateY(17deg)
      rotateX(0deg);
    right: 44rem;
    top: -3rem;
  }
  .group1 .poker:nth-child(2) {
    transform: rotateX(-4deg) rotateZ(-6deg) translateZ(2vmin) rotateY(21deg)
      rotateX(0deg);
    right: 38rem;
    top: -6rem;
  }
  .group1 .poker:nth-child(3) {
    transform: rotateX(0) rotateZ(0deg) translateZ(3vmin) rotateY(0deg)
      rotateX(0deg);
    right: 33rem;
    top: -7rem;
  }
  .group1 .poker:nth-child(4) {
    transform: rotateX(7deg) rotateZ(9deg) translateZ(4vmin) rotateY(0deg)
      rotateX(0deg);
    right: 25rem;
    top: -6rem;
  }
  .group1 .poker:nth-child(5) {
    transform: rotateX(0deg) rotateZ(13deg) translateZ(5vmin) rotateY(0deg)
      rotateX(15deg);
    right: 17rem;
    width: 200px;
    height: 280px;
    top: -9rem;
  }
  .group2 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-21deg) translateZ(1vmin) rotateY(17deg)
          rotateX(0deg);
        right: 44rem;
        top: -3rem;
      }
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-6deg) translateZ(1vmin)
          rotateY(21deg) rotateX(0deg);
        right: 38rem;
        top: -6rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(1vmin) rotateY(0deg)
          rotateX(0deg);
        right: 33rem;
        top: -7rem;
      }
      &:nth-child(4) {
        transform: rotateX(7deg) rotateZ(9deg) translateZ(1vmin) rotateY(0deg)
          rotateX(0deg);
        right: 25rem;
        width: 200px;
        height: 280px;
        top: -9rem;
      }
      &:nth-child(5) {
        transform: rotateX(0deg) rotateZ(24deg) translateZ(1vmin) rotateY(0deg)
          rotateX(0deg);
        right: 18rem;
        top: -3rem;
      }
    }
  }
  .group3 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-21deg) translateZ(9vmin) rotateY(17deg)
          rotateX(0deg);
        right: 44rem;
        top: -3rem;
      }
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-6deg) translateZ(11vmin)
          rotateY(21deg) rotateX(0deg);
        right: 38rem;
        top: -6rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(11vmin) rotateY(0deg)
          rotateX(0deg);
        right: 33rem;
        width: 200px;
        height: 280px;
        top: -10rem;
      }
      &:nth-child(4) {
        transform: rotateX(7deg) rotateZ(9deg) translateZ(14vmin) rotateY(0deg)
          rotateX(0deg);
        top: -5rem;
        right: 25rem;
      }
      &:nth-child(5) {
        transform: rotateX(0deg) rotateZ(24deg) translateZ(6vmin) rotateY(0deg)
          rotateX(0deg);
        right: 18rem;
        top: -3rem;
      }
    }
  }
  .group4 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-21deg) translateZ(9vmin) rotateY(17deg)
          rotateX(0deg);
        right: 44rem;
        top: -3rem;
      }
      &:nth-child(2) {
        transform: rotateX(0deg) rotateZ(-15deg) translateZ(11vmin)
          rotateY(21deg) rotateX(0deg);
        right: 38rem;
        width: 200px;
        height: 280px;
        top: -10rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(11vmin) rotateY(0deg)
          rotateX(0deg);
        top: -5rem;
        right: 33rem;
      }
      &:nth-child(4) {
        transform: rotateX(4deg) rotateZ(11deg) translateZ(14vmin) rotateY(0deg)
          rotateX(0deg);
        top: -5rem;
        right: 25rem;
      }
      &:nth-child(5) {
        transform: rotateX(5deg) rotateZ(26deg) translateZ(6vmin) rotateY(0deg)
          rotateX(0deg);
        right: 18rem;
        top: -2rem;
      }
    }
  }
  .content__line {
    padding: 0 3rem;
  }
}
@media screen and (max-width: 767px) {
  .sec_txt {
    font-size: 1.5rem;
    padding: 0;
  }
  .content__line {
    padding: 0 1.5rem;
  }
  .content {
    height: 85vh;
  }
  //背景文字
  .screen {
    height: 75vh;
  }
  .sec_txt {
    font-size: 1.75rem;
    white-space: normal;
    width: 100%;
    position: absolute;
    text-align: initial;
    padding: 1rem 0;
  }
  .type {
    --type-size: 10vw;
    line-height: 1.1;
  }
  .type--2.pd {
    margin-top: 5rem;
  }
  .fold-after {
    padding-top: 0;
    top: -70px;
  }
  // 撲克牌
  .poker_area {
    height: 70%;
    bottom: 18%;
  }
  .poker_group {
    right: unset;
    left: 0;
  }
  .poker_box {
    margin-top: 60%;
  }
  .poker {
    width: 100px;
    height: 140px;
    &::before {
      font-size: 1rem;
    }
    &::after {
      font-size: 1rem;
      top: 1.25rem;
      left: 0.5rem;
    }
    &7 {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
    &8 {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
    &9 {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
    &10 {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
    &J {
      &::before {
        left: 10px;
        top: 0.5rem;
        font-size: 1.5rem;
      }
      &::after {
        top: 2rem;
      }
    }
    &Q {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
    &K {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
    &A {
      &::before {
        left: 9px;
        top: 0rem;
      }
    }
  }
  .group1 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-32deg) translateZ(1vmin) rotateY(17deg)
          rotateX(0deg);
        right: unset;
        top: -1rem;
        left: 3rem;
      }
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-13deg) translateZ(2vmin)
          rotateY(21deg) rotateX(0deg);
        right: unset;
        top: -2rem;
        left: 6rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(5vmin) rotateY(0deg)
          rotateX(0deg);
        right: unset;
        top: -2rem;
        left: 9rem;
      }
      &:nth-child(4) {
        transform: rotateX(7deg) rotateZ(9deg) translateZ(6vmin) rotateY(0deg)
          rotateX(0deg);
        right: 7rem;
        top: -1.5rem;
      }
      &:nth-child(5) {
        transform: rotateX(0deg) rotateZ(13deg) translateZ(7vmin) rotateY(0deg)
          rotateX(15deg);
        right: 2rem;
        width: 120px;
        height: 170px;
        top: -7rem;
      }
    }
  }
  .group2 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-32deg) translateZ(1vmin) rotateY(17deg)
          rotateX(0deg);
        right: unset;
        top: -1rem;
        left: 3rem;
      }
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-13deg) translateZ(4vmin)
          rotateY(21deg) rotateX(0deg);
        right: unset;
        top: -2rem;
        left: 6rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(7vmin) rotateY(0deg)
          rotateX(0deg);
        right: unset;
        top: -2rem;
        left: 9rem;
      }
      &:nth-child(4) {
        transform: rotateX(7deg) rotateZ(9deg) translateZ(10vmin) rotateY(0deg)
          rotateX(0deg);
        right: 5rem;
        width: 120px;
        height: 170px;
        top: -7rem;
      }
      &:nth-child(5) {
        transform: rotateX(0deg) rotateZ(19deg) translateZ(13vmin) rotateY(0deg)
          rotateX(15deg);
        right: 2rem;
        top: -1.5rem;
      }
    }
  }
  .group3 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-32deg) translateZ(1vmin) rotateY(17deg)
          rotateX(0deg);
        right: unset;
        top: -1rem;
        left: 3rem;
      }
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-13deg) translateZ(3vmin)
          rotateY(21deg) rotateX(0deg);
        right: unset;
        top: -2rem;
        left: 6rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(6vmin) rotateY(0deg)
          rotateX(0deg);
        right: unset;
        left: 9rem;
        width: 120px;
        height: 170px;
        top: -7rem;
      }
      &:nth-child(4) {
        transform: rotateX(7deg) rotateZ(9deg) translateZ(9vmin) rotateY(0deg)
          rotateX(0deg);
        right: 6rem;
        top: -2rem;
      }
      &:nth-child(5) {
        transform: rotateX(0deg) rotateZ(22deg) translateZ(12vmin) rotateY(0deg)
          rotateX(15deg);
        right: 2rem;
        top: -1.5rem;
      }
    }
  }
  .group4 {
    .poker {
      &:nth-child(1) {
        transform: rotateX(0) rotateZ(-32deg) translateZ(1vmin) rotateY(17deg)
          rotateX(0deg);
        right: unset;
        top: -1rem;
        left: 3rem;
      }
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-13deg) translateZ(4vmin)
          rotateY(21deg) rotateX(0deg);
        right: unset;
        left: 5rem;
        width: 120px;
        height: 170px;
        top: -7rem;
      }
      &:nth-child(3) {
        transform: rotateX(0) rotateZ(0deg) translateZ(7vmin) rotateY(0deg)
          rotateX(0deg);
        right: unset;
        left: 9rem;
        top: -3rem;
      }
      &:nth-child(4) {
        transform: rotateX(7deg) rotateZ(12deg) translateZ(9vmin) rotateY(0deg)
          rotateX(0deg);
        right: 5rem;
        top: -2.25rem;
      }
      &:nth-child(5) {
        transform: rotateX(0deg) rotateZ(25deg) translateZ(13vmin) rotateY(0deg)
          rotateX(15deg);
        right: 2rem;
        top: -1.5rem;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  // 撲克牌
  .group1 {
    .poker {
      &:nth-child(2) {
        left: 5rem;
      }
      &:nth-child(3) {
        left: 7rem;
      }
      &:nth-child(4) {
        right: 7rem;
      }
    }
  }
  .group2 {
    .poker {
      &:nth-child(4) {
        right: 3rem;
      }
    }
  }
  .group3 {
    .poker {
      &:nth-child(3) {
        left: 8rem;
      }
    }
  }
  .group4 {
    .poker {
      &:nth-child(2) {
        transform: rotateX(-4deg) rotateZ(-18deg) translateZ(11vmin)
          rotateY(21deg) rotateX(0deg);
        left: 4.5rem;
      }
      &:nth-child(4) {
        right: 4rem;
      }
    }
  }
  .sec_txt {
    font-size: 20px;
  }
}
</style>
