<template>
  <NavHeader></NavHeader>
  <router-view />
  <AllFooter></AllFooter>
</template>

<style>
@import "./css/bootstrap.scss";
@import "./css/color_one.scss";
@import "./css/style.scss";
@import "./css/iconmoon.scss";

#app {
  font-size: 16px;
  color: #333;
  font-family: "微軟正黑體", "Raleway", sans-serif;
  font-family: "Josefin Sans", sans-serif;
  font-family: "Raleway", sans-serif;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1px;
}
* {
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
}

.section{
  padding: 5rem 0;
}
a{
  text-decoration: none !important;
  font-size: 1.125rem;
}
a:hover{
   text-decoration: none !important;
}
img{
  max-width: 100%;
}
/* 上傳檔案 */
.bus_upimg{
  max-width: calc(100% / 5 - 2rem);
  margin: 1rem 1rem;
}
.showFileName button{
    margin: 0.5rem;
    background: #e14545;
    border: none;
    color: #fff;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
  }
  @media screen and (max-width:992px) {
    .section{
  padding: 3rem 0;
  }
  }
</style>
<script>
import AllFooter from '@/components/AllFooter.vue'
import NavHeader from '@/components/NavHeader.vue'
export default {
  name: 'HomeView',
  components: { AllFooter, NavHeader }

}
</script>
