<!-- 網站footer元件 -->
<template>
  <div class="section footer_bg">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>聯絡我們</h2>
          <p class="en_title text-light">CONTACT US</p>
        </div>
        <div class="col-xl-6 col-12 mt-4 contact_txt">
          <p>
            如果您有任何的疑問，都歡迎和我們聯繫。在合作的過程中，我們不僅樂於分享經驗，您的每一個意見，我們也將虛心受教；您的每一個看法，我們將以您的角度仔細揣摩，再依您的需求量身打造。
          </p>
          <p>服務專線：07 554-3800</p>
        </div>
        <div class="col-xl-6 col-12 link_block">
          <div class="footer_mail">
            <a href="mailto:web@spadej.com">web@spadej.com</a>
            <a href="mailto:web@spadej.com" class="mail_btn">歡迎來信洽談</a>
          </div>
          <div class="footer_link">
            <router-link to="/about">{{ $store.state.menuTitleone }}</router-link>
            <router-link to="/webdesign">{{ $store.state.menuTitletwo }}</router-link>
            <router-link to="/system">{{$store.state.menuTitleThree}}</router-link>
            <router-link to="/webdesign">成功案例</router-link>
            <router-link to="/recruit">{{ $store.state.menuTitlefour }}</router-link>
          </div>
          <p class="cc_txt">
            思倍傑資訊股份有限公司 版權所有 © 2024 SPADEJ All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div id="scrollUp" class="hide" style="display: block" v-on="TOtop()">
    <i class="icon-top"></i>
    <p class="top_txt">Top</p>
  </div>
  <!-- 待好架網上線開啟LINE好友功能 -->
  <div id="LineQRcode" style="display: block" v-on="QRopen()">
    <img src="@/assets/LINE_img.png">
  </div>
  <div class="LINEQR_box">
    <img src="@/assets/LINEQRcode.png">
    <p>歡迎加入LINE好友</p>
  </div>
</template>
<style  lang="scss" scoped>
.section {
  padding: 60px 0 10px;
}
.section h2 {
  font-size: 3.5rem;
  color: #fff;
}
.section .en_title {
  font-size: 6rem;
  font-weight: bold;
  margin-top: 1.5rem;
  -webkit-text-stroke: 1px #fff;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1;
}
.footer_bg {
  background: url(@/assets/footer_bg.png) var(--seccolor);
  background-size: cover;
  border-radius: 0 45px 0 0;
  color: #fff;
  margin-right: 8rem;
  padding-left: 8rem;
  .link_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.footer_mail {
  a {
    color: #fff;
    font-size: 1.75rem;
    margin: 0 1.5rem;
  }
  a:nth-child(1) {
    text-decoration: underline !important;
  }
  .mail_btn {
    background: #fff;
    color: #3d3d3d !important;
    border-radius: 41px;
    padding: 1rem 2.5rem;
    transition: 0.3s;
  }
  .mail_btn:hover {
    background: var(--thirdcolor);
    color: #fff !important;
  }
}
.footer_link {
  display: flex;
  margin-top: 2rem;
}
.footer_link a {
  margin: 1rem 1.5rem;
  color: #fff;
}
a:hover {
  color: var(--black);
}
.cc_txt {
  font-size: 1rem;
  text-align: end;
  margin: 1rem 1rem 1rem;
}
#scrollUp {
  text-align: center;
  bottom: 25px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 2rem;
  z-index: 997;
  border-radius: 50px 50px 4px 4px;
  font-size: 3rem;
}
#scrollUp:hover {
  color: var(--maincolor);
}
#scrollUp.hide {
  opacity: 0;
  filter: Alpha(opacity=0);
  transform: translateY(150%);
}
// LINE ICON
#LineQRcode{
  bottom: 150px;
  cursor: pointer;
  display: none;
  position: fixed;
  right: 25px;
  z-index: 997;
  width: 4rem;
  height: 4rem;
}
.LINEQR_box{
  position: fixed;
  bottom: 180px;
  right: 50px;
  background: rgb(255 255 255 / 90%);
  border-radius: 15px;
  z-index: 996;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 8px var(--shodowColor);
  padding: 1rem 0;
  width: 12%;
  opacity: 0;
  transform: translate(20px,20px);
  transition: .3s;
  img{
    width: 50%;
  }
  &.active{
    opacity: 1;
    display: flex;
    transform: translate(0,0);
  }
  p{
    font-weight: 500;
    margin-top: .5rem;
  }
}
@media screen and (max-width: 1400px) {
  .footer_bg {
    background-size: cover;
    padding-left: 1rem;
  }
  .footer_mail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .footer_mail a {
    margin: 1rem;
    margin-left: 0;
  }
  .footer_link {
    flex-wrap: wrap;
  }
  .footer_bg .link_block {
    justify-content: flex-start;
  }
  .footer_link a,
  .cc_txt {
    margin-left: 0;
  }
  .contact_txt{
    padding-right: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .section .en_title{
    line-height: 1;
    font-size: 4rem;
  }
  .footer_link{
    margin-top: 1rem;
    a{
      width: 100%;
      font-size: 1.25rem;
    }
  }
  .footer_mail a:nth-child(1){
    word-break: break-word;
  }
  .cc_txt{
    text-align: start;
  }
  .footer_bg{
    margin-right: 0;
  }
  #scrollUp{
    bottom: 0px;
    right: 0.5rem;
  }
  .LINEQR_box{
    width: 50%;
  }
  .footer_mail a{
    margin: 1rem 0;
  }
  #LineQRcode{
    width: 3.5rem;
    height: 3.5rem;
    right: 7px;
    bottom: 110px;
  }
}
</style>

<script>
import $ from 'jquery'
export default {
  methods: {
    TOtop: function () {
      $(document).ready(function () {
        // 捲軸偵測距離頂部超過 50 才顯示按鈕
        $(window).scroll(function () {
          if ($(window).scrollTop() > 200) {
            if ($('#scrollUp').hasClass('hide')) {
              $('#scrollUp').toggleClass('hide')
            }
          } else {
            $('#scrollUp').addClass('hide')
          }
        })

        // 點擊按鈕回頂部
        $('#scrollUp').on('click', function () {
          $('html, body').animate(
            {
              scrollTop: 0
            },
            500 // 回頂部時間為 500 毫秒
          )
        })
      })
    },
    QRopen: function () {
      $(document).ready(function () {
        $('#LineQRcode').on('click', function () {
          $('.LINEQR_box').toggleClass('active')
        })
      })
    }
  }
}
</script>
